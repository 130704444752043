import React from 'react';
import { useAuthContext, Profile } from '../context/AuthContext';
import { useCwContext } from '../context/CwContext';

function LoggedIn({ profile }: { profile: Profile }) {
  return (
    <main>
      <h1>Welcome, {profile.user?.name}</h1>
    </main>
  );
}

function LoggedOut() {
  return (
    <main>
      <h1>Welcome</h1>
      <a href="/auth/signin">Sign in with SSO</a>
    </main>
  );
}

export default function LoginPage() {
  const { profile } = useAuthContext();
  const { sessionState } = useCwContext();
  return (
    <div>
      {profile.loggedIn && sessionState.loggedIn ? (
        <LoggedIn profile={profile} />
      ) : (
        <LoggedOut />
      )}
    </div>
  );
}
