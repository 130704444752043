import React from 'react';
import { useAuthContext, Profile } from '../context/AuthContext';
import { useCwContext } from '../context/CwContext';

import styles from './Login.module.css';

function LoggedIn({
  profile,
  handleLogout,
}: {
  profile: Profile;
  handleLogout: () => void;
}) {
  return (
    <div>
      <span className={styles.loggedInUser}>{profile.user?.name}</span>
      <button className={styles.logoutButton} onClick={handleLogout}>
        Logout
      </button>
    </div>
  );
}

function LoggedOut({ handleLogin }: { handleLogin: () => void }) {
  return (
    <div>
      <button onClick={handleLogin}>Login</button>
    </div>
  );
}

export function Login() {
  const { profile, handleLogin, handleLogout } = useAuthContext();
  const { sessionState } = useCwContext();

  return (
    <div>
      {sessionState.loggedIn && profile.loggedIn ? (
        <LoggedIn profile={profile} handleLogout={handleLogout} />
      ) : (
        <LoggedOut handleLogin={handleLogin} />
      )}
    </div>
  );
}
