function unitize(qty: number, unit: string, long: boolean) {
  if (!long) return `${qty}${unit === 'month' ? 'mo' : unit[0]}`;
  if (qty == 1) return `${qty} ${unit}`;
  return `${qty} ${unit}s`;
}
export default function relativeTime(
  time: number,
  long: boolean = false,
): string {
  let units = time - Date.now();

  const format =
    units > 0
      ? (time: number, unit: string) => `in ${unitize(time, unit, long)}`
      : (time: number, unit: string) => `${unitize(time, unit, long)} ago`;
  units = Math.abs(units);
  if (units < 1000) return 'now';
  units = Math.round(units / 1000);
  if (units < 60) return format(units, 'second');

  units = Math.round(units / 60);
  if (units < 60) return format(units, 'minute');

  units = Math.round(units / 60);
  if (units < 24) return format(units, 'hour');

  units = Math.round(units / 24);
  if (units < 7) return format(units, 'day');
  if (units < 30) return format(Math.round(units / 7), 'week');
  if (units < 365) return format(Math.round(units / 30), 'month');
  return format(Math.round(units / 365), 'year');
}
