import React from 'react';
import { useAuthContext, AuthProvider } from './context/AuthContext';
import { CwProvider, useCwContext } from './context/CwContext';

import Home from './pages/Home';
import LoginPage from './pages/LoginPage';

import styles from './App.module.css';

function Switch() {
  const { sessionState } = useCwContext();
  return sessionState.loggedIn ? <Home /> : <LoginPage />;
}

function App() {
  return (
    <AuthProvider>
      <CwProvider>
        <Switch />
      </CwProvider>
    </AuthProvider>
  );
}

export default App;
