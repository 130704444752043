import React, { useEffect } from 'react';
import axios from 'axios';

export interface User {
  name: string;
}

export interface Profile {
  loggedIn: boolean;
  user: User | null;
}

export interface IAuthContext {
  profile: Profile;
  handleLogin: () => void;
  handleLogout: () => void;
  refresh: () => void;
}
const AuthContext = React.createContext({
  profile: { loggedIn: false } as Profile,
  handleLogin: () => {},
  handleLogout: () => {},
  refresh: () => {},
});

export const useAuthContext = () => React.useContext(AuthContext);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [profile, setProfile] = React.useState<Profile>({
    loggedIn: false,
    user: null,
  });

  const handleLogin = async () => {
    window.location.href = `${window.location.origin}/auth/signin`;
  };

  const handleLogout = async () => {
    window.location.href = `${window.location.origin}/auth/signout`;
  };

  const refresh = async () => {
    setProfile((await axios.get('/api.profile')).data);
  };

  useEffect(() => {
    (async () => {
      setProfile((await axios.get('/api/profile')).data);
    })();
  }, []);

  const value: IAuthContext = {
    profile,
    handleLogin,
    handleLogout,
    refresh,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
