import React from 'react';
import { useAuthContext, Profile } from '../context/AuthContext';

import { Login } from '../components/Login';
import { TicketBot } from '../components/TicketBot';

export default function Home() {
  const { profile } = useAuthContext();
  return (
    <main>
      <Login />
      <TicketBot />
    </main>
  );
}
