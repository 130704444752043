import React, { useEffect, useState } from 'react';
import { ITicket, useCwContext } from '../context/CwContext';

import styles from './TicketBot.module.css';
import relativeTime from '../lib/relative-time';

export function Queue({ title, count }: { title: string; count?: number }) {
  if (!title) return <div>--</div>;
  return (
    <div className={styles.queue}>
      <div className={styles.queueName}>{title}</div>
      <div className={styles.queueCount}>{count}</div>
    </div>
  );
}

function TicketLink({
  id,
  ticketNum,
  summary,
  hyperlink,
}: {
  id: number;
  ticketNum: string;
  summary: string;
  hyperlink: string;
}) {
  return <a href={hyperlink} title={summary} target="_blank">{`#${ticketNum}`}</a>;
}

function RelativeTime({ time, now }: { time: number; now: number }) {
  return <span className={styles.time}>{relativeTime(time)}</span>;
}

function Ticket({
  ticket,
  now,
}: {
  ticket: {
    id: number;
    ticketNum: string;
    companyId: string;
    summary: string;
    hyperlink: string;
    assignedTime?: number;
  };
  now: number;
}) {
  return (
    <div>
      <TicketLink
        id={ticket.id}
        ticketNum={ticket.ticketNum}
        summary={ticket.summary}
        hyperlink={ticket.hyperlink}
      />{' '}
      {ticket.assignedTime && (
        <RelativeTime time={ticket.assignedTime} now={now} />
      )}
    </div>
  );
}

function TicketButton({
  disabled,
  now,
  handler,
}: {
  disabled: boolean;
  now: number;
  handler: () => Promise<ITicket>;
}) {
  const [fetching, setFetching] = useState(false);
  const [ticket, setTicket] = useState<ITicket | undefined>();
  const clickHandler = async () => {
    setFetching(true);
    const ticket = await handler();
    setTicket(ticket);
    setFetching(false);
  };
  return fetching ? (
    <span>Fetching...</span>
  ) : ticket && now - ticket.assignedTime < 30000 ? (
    <Ticket ticket={ticket} now={now} />
  ) : (
    <button onClick={clickHandler} disabled={disabled}>
      Get Ticket
    </button>
  );
}

export function TicketBot() {
  const { queues, sessionState, nextTicket, refresh } = useCwContext();
  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  });

  const queueName = sessionState.cwProfile?.queueName;
  const queue = queueName && queues[queueName];
  return (
    <div className={styles.ticketBot}>
      {sessionState.cwProfile?.lastTicket && (
        <Ticket ticket={sessionState.cwProfile.lastTicket} now={time} />
      )}
      {typeof queue === 'number' && queueName ? (
        <Queue title={queueName} count={queue} />
      ) : (
        <span>No queue assigned to your user.</span>
      )}
      <TicketButton
        disabled={
          !queue ||
          queue < 1 ||
          Date.now() - (sessionState.cwProfile?.lastTicket?.assignedTime || 0) <
            30000
        }
        now={time}
        handler={nextTicket}
      />
      <button onClick={refresh}>Refresh</button>
    </div>
  );
}
