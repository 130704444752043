import React, { useEffect } from 'react';
import axios from 'axios';

import { useAuthContext } from './AuthContext';

export interface ITicket {
  id: number;
  ticketNum: string;
  companyId: string;
  summary: string;
  hyperlink: string;
  assignedTime: number;
}

export interface ProfileState {
  identifier: string;
  id: number;
  queueName?: string;
  lastTicket?: ITicket;
}

export interface SessionState {
  loggedIn?: boolean;
  cwProfile?: ProfileState;
}

export type QueueCounts = { [key: string]: number };

export interface ICwContext {
  queues: QueueCounts;
  sessionState: SessionState;
  lastClick: number;
  nextTicket: () => Promise<ITicket>;
  refresh: () => Promise<void>;
}

const CwContext = React.createContext({
  queues: {} as QueueCounts,
  sessionState: {} as SessionState,
  lastClick: 0,
  nextTicket: () => {},
  refresh: () => {},
} as ICwContext);

export const useCwContext = () => React.useContext(CwContext);

export function CwProvider({ children }: { children: React.ReactNode }) {
  const [queues, setQueues] = React.useState<QueueCounts>({});
  const [sessionState, setSessionState] = React.useState<SessionState>({});
  const [lastClick, setLastClick] = React.useState(0);
  const { refresh: refreshAuth } = useAuthContext();

  const nextTicket = async (): Promise<ITicket> => {
    setLastClick(Date.now());
    const ticket = await axios.get('/api/canhazticket');
    setSessionState({
      ...sessionState,
      cwProfile: sessionState.cwProfile
        ? { ...sessionState.cwProfile, lastTicket: ticket.data }
        : undefined,
    });
    return ticket.data;
  };

  const refresh = async (): Promise<void> => {
    await axios.get('/api/counts');
  };

  //useEffect(() => {}, []); // Load /api/profile

  useEffect(() => {
    const source = new EventSource('/api/events');
    source.addEventListener('queues', (e) => {
      setQueues(JSON.parse(e.data));
    });

    source.addEventListener('session', (e) => {
      const newState = JSON.parse(e.data);
      setSessionState(newState);
    });

    return () => {
      source.close();
    };
  }, []);

  const value: ICwContext = {
    queues,
    sessionState: sessionState,
    lastClick,
    nextTicket,
    refresh,
  };

  return <CwContext.Provider value={value}>{children}</CwContext.Provider>;
}
